<div class="row">
  <div class="col-lg-12 mb-4">
    <app-card header="qc.title" [isLoading]="formLoading" [isSaving]="formSaving">
      <div id="accordion">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                aria-controls="collapseOne">Search</button>
            </h5>
          </div>

          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body" style="padding:2rem">

              <form class="form-horizontal" [formGroup]="formGroup" novalidate>

                <div class="form-row ">
                  <div class="col-md-3 mb-3">
                    <label for="validationCustom01">Tanggal mulai</label>
                    <app-date-picker size="XL" formControlName="dateFrom"></app-date-picker>

                  </div>
                  <div class="col-md-3 mb-3">
                    <label for="validationCustom02">Tanggal selesai</label>
                    <app-date-picker size="XL" formControlName="dateTo"></app-date-picker>
                  </div>

                </div>
                <div class="form-row mt-2">
                  <div class="col-md-3 mb-3">
                    <label for="validationCustom03">Group</label>
                    <app-dropdown-select showLimitValue="3" type="CHOSEN" size="XL" formControlName="qcType"
                      placeholder="- select -" [optionList]="qcTypeOptionList"> </app-dropdown-select>

                  </div>
                  <div class="col-md-3 mb-3">
                    <label for="validationCustom04">Shift</label>

                    <app-dropdown-select showLimitValue="3" type="CHOSEN" size="XL" formControlName="shift"
                      placeholder="- select -" [optionList]="shiftOptionList"> </app-dropdown-select>
                  </div>
                  <div class="col-md-3 mb-3">
                    <label for="validationCustom05">Qc</label>
                    <app-dropdown-select showLimitValue="3" type="CHOSEN" size="XL" formControlName="qcUser"
                      placeholder="- select -" [optionList]="qcUserOptionList"> </app-dropdown-select>
                  </div>
                </div>
                <div class="form-row">

                  <div class="col-md-12 mb-1 mt-4 text-right">
                    <button (click)="doExport()" class="btn btn-primary  mr-2"> <i class="mr-2 fa"
                        [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'"></i> Print</button>
                    <button class="btn btn-outline-secondary mr-2">Reset</button>
                    <button (click)="doApply()" class="btn btn-primary"><i class="fa fa-search mr-2"></i>Cari</button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>


      <app-table [model]="tableResponse" [isServerSide]="true" stringUrl="/qc/index/0">
        <ng-template #actionButtons let-rowData>
          <div class="d-flex justify-content-center" >
            <app-button  size="TN"  class="mr-1" (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}">
              <em class="fa fa-pencil-alt"></em>
            </app-button>
            <app-button size="TN" color="DANGER" (onClick)="doDelete(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}">
              <em class="fa  fa-trash"></em>
            </app-button>
          </div>
        </ng-template>

      </app-table>
    </app-card>
  </div>
</div>