import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildInfoDTO } from '../dto/build-info.dto';
import { Global } from '../global/global';
import { ConfigModel } from '../model/config-model';
import { AppInfoResponse } from '../response/app-info-response';
import { AuthenticationService } from './authentication.service';
import { LoaderService } from './loader.service';
import { Log } from './logger';
import { ServiceLocator } from './service-locator';
@Injectable()
export class AppLoadService {
  public log: Log = new Log(this);
  constructor(
    public httpClient: HttpClient,
    private loaderService: LoaderService
  ) {}

  async initializeApp(): Promise<boolean> {
    this.loaderService.showInfo('Initialize Application...', true);
    return this.getLocalConfig()
      .then(() => this.getBackendConfig())
      .then(() => this.getBuildInfo())
      .then(() => this.synchronizeTime())
      .then(() => this.finishingInitializeApp());
  }

  public getLocalConfig(): Promise<ConfigModel> {
    return new Promise((resolve, reject) => {
      this.loaderService.showInfo('Load local configuration...');
      this.httpClient.get<ConfigModel>('//assets/config.json').subscribe(
        (configModel: ConfigModel) => {
          const global = ServiceLocator.injector.get(Global);
          const appInfoResponse = global.config.appInfoResponse;
          const allowedFile = global.config.allowedFile;
          global.config = configModel;
          global.config.appInfoResponse =
            configModel.appInfoResponse || appInfoResponse;
          global.config.allowedFile = allowedFile;
          global.config.BACKEND_ADDRESS = configModel.BASE_ADDRESS + '/api';
          global.config.WEBSOCKET_ADDRESS =
            configModel.BASE_ADDRESS + '/websocket';
          this.log.debug('[ GET SETTING ]');
          this.log.debug(
            '[ BACKEND_ADDRESS ]   = ' + global.config.BACKEND_ADDRESS
          );
          this.log.debug('[ Local Config loaded!! ]');
          this.loaderService.showInfo('Local configuration loaded.');
          resolve(configModel);
        },
        (error: HttpErrorResponse) => {
          this.loaderService.showError('Failed to load local configuration');
          reject(error);
        }
      );
    });
  }

  public getBackendConfig(): Promise<AppInfoResponse> {
    return new Promise((resolve, reject) => {
      this.loaderService.showInfo('Get backend configuration...');
      this.httpClient.get<AppInfoResponse>('/app-info/load', {}).subscribe(
        (appInfoResponse: AppInfoResponse) => {
          this.log.debug(appInfoResponse.activeLangList);
          const global = ServiceLocator.injector.get(Global);
          global.config.appInfoResponse = appInfoResponse;
          global.config.parameterModel = appInfoResponse.parameterModel;
          appInfoResponse.fileTypeList.forEach(fileType => {
            global.config.allowedFile.set(fileType.code, fileType);
          });

          if (!appInfoResponse.parameterModel.consoleLogEnable && window) {
            window.console.log = () => {};
            window.console.group = () => {};
            window.console.groupEnd = () => {};
          }
          this.log.debug(
            '[ SHOW CONSOLE LOG ]  = ' +
              appInfoResponse.parameterModel.consoleLogEnable
          );
          this.log.debug(
            '[ CONSTANT MAP interfacing >> ]' +
              // tslint:disable-next-line: no-string-literal
              appInfoResponse.parameterModel.isInterfacing
          );
          this.log.debug(
            '[ CONSTANT MAP pm address url >> ]' +
              // tslint:disable-next-line: no-string-literal
              appInfoResponse.parameterModel.pmAddressUrl
          );
          this.log.debug('[ Backend Config loaded!! ]');
          this.loaderService.showInfo('Backend configuration loaded.');
          resolve(appInfoResponse);
        },
        (error: HttpErrorResponse) => {
          this.loaderService.showError('Failed to load backend configuration.');
          reject(error);
        }
      );
    });
  }

  public getBuildInfo(): Promise<BuildInfoDTO> {
    return new Promise(resolve => {
      this.loaderService.showInfo('Get build info...');
      this.httpClient.get<BuildInfoDTO>('/app-info/get-build-info').subscribe(
        (buildInfoDTO: BuildInfoDTO) => {
          const global = ServiceLocator.injector.get(Global);
          global.buildInfoDTO = buildInfoDTO;

          const authenticationService = ServiceLocator.injector.get(
            AuthenticationService
          );
          authenticationService.relogin();
          this.log.debug('[ Build Info loaded!! ]');
          this.loaderService.showInfo('Build info loaded.');
          resolve(buildInfoDTO);
        },
        () => {
          this.loaderService.showError('Failed to load build info');
          resolve('' as any);
        }
      );
    });
  }

  public synchronizeTime(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.loaderService.showInfo('Synchronize time...');
      this.httpClient.get<Date>('/app-info/get-server-time', {}).subscribe(
        date => {
          const global = ServiceLocator.injector.get(Global);
          const serverTime = new Date(date);
          const localTime = new Date();

          global.deltaTime = serverTime.getTime() - localTime.getTime();
          this.loaderService.showInfo('Time synchronize');
          resolve(global.deltaTime);
        },
        (error: HttpErrorResponse) => {
          this.loaderService.showError('Failed to synchronize time');
          reject(error);
        }
      );
    });
  }

  public finishingInitializeApp(): Promise<boolean> {
    return new Promise(resolve => {
      this.loaderService.close();
      resolve(true);
    });
  }
}
