<aside class="main-sidebar sidebar-dark-primary elevation-4 ">

  <a class="brand-link">
    <img src="assets/images_portal/compactlogo.png" alt="COMEL Logo" class="brand-image img-circle elevation-3"
      style="opacity: .8">
    <span class="brand-text font-weight-light">PRIMECHECK</span>
  </a>
  
  <div class="sidebar" (mousemove)="onMouseMove($event)">

    <div class="form-inline">
      <form [formGroup]="formGroup">
        <div class="input-group mt-2" data-widget="sidebar-search">
          <input class="form-control form-control-sidebar" [formControl]="formGroup.get('search')" type="search" (keydown)="onKeyDown($event)"
            placeholder="Search" aria-label="Search"  tabindex="1">
          <div class="input-group-append">
            <button *ngIf="keyword.length ==  0" class="btn btn-sidebar"  tabindex="2">
              <i class="fas fa-search fa-fw"></i>
            </button>
            <button *ngIf="keyword.length  >  0" (click)="resetSearchInput()" class="btn btn-sidebar"  tabindex="2">
              <i class="fas fa-times fa-fw"></i>
            </button>
          </div>
        </div>
      </form>
    </div>

    <nav class="mt-2">
      <ul class="nav nav-child-indent  nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
        data-accordion="false">
        <li class="nav-item" [ngClass]="{ 'menu-open active': menu.isActive, 'menu-is-opening': menu.isExpanded }"
          *ngFor="let menu of menuItems let i = index" (click)="doClickMenuItem($event, menu)" [attr.id]="'menuItem' + menu.id">

          <a class="nav-link" [ngClass]="{ 'active': menu.isActive }" style="cursor: pointer;" tabindex="{{i+3}}">
            <i *ngIf="menu.childrens.length > 0" class="nav-icon far" 
              [ngClass]="{'fa-folder-open': menu.isExpanded, 'fa-folder': !menu.isExpanded }"></i>
            <i *ngIf="menu.childrens.length == 0" class="nav-icon " [ngClass]="menu.icon"></i>
            <p>
              <span [innerHTML]="( 'sidebar.' + menu.translateKey | translate |  coloringByKeyword :keyword )"></span>
              <i *ngIf="menu.childrens.length > 0" class="fas fa-angle-left right "
                [@rotate]="!menu.isExpanded ? 'default' : 'rotated90ccw'"></i>
            </p>
          </a>

          <ng-container *ngIf="menu.childrens.length > 0">
            <ng-container
              *ngTemplateOutlet=" subMenuTemplate; context: { $implicit: menu.childrens, parent: menu }"></ng-container>
          </ng-container>

        </li>
      </ul>
    </nav>
  </div>
</aside>

<ng-template #subMenuTemplate let-menuList let-parent="parent">
  <ul class="nav nav-treeview" [@divExpandedCollapsed]="parent.isExpanded?'expanded':'collapsed'"
    [ngClass]="{ 'd-block': parent.isExpanded, 'd-none' : !parent.isExpanded }">
    <li class="nav-item "
      [ngClass]="[ menu.isExpanded ? 'menu-is-opening' : '', menu.isActive ? 'menu-open active' : '']"
      (click)="doClickMenuItem($event, menu)" *ngFor="let menu of menuList" [attr.id]="'menuItem' + menu.id">
      <a class="nav-link" [ngClass]="{ 'active': menu.isActive }" style="cursor: pointer;">
        <i *ngIf="menu.childrens.length > 0" class="nav-icon far"
          [ngClass]="{'fa-folder-open': menu.isExpanded, 'fa-folder': !menu.isExpanded }"></i>
        <i *ngIf="menu.childrens.length == 0" class="nav-icon " [ngClass]="menu.icon"></i>
        <p> <span [innerHTML]="( 'sidebar.' + menu.translateKey | translate |  coloringByKeyword :keyword )"></span>
          <i *ngIf="menu.childrens.length > 0" class="fas fa-angle-left right "
            [@rotate]="!menu.isExpanded ? 'default' : 'rotated90ccw'"></i>
        </p>
      </a>
      <ng-container *ngIf="menu.childrens.length > 0">
        <ng-container
          *ngTemplateOutlet="subMenuTemplate;  context: { $implicit: menu.childrens, parent: menu } "></ng-container>
      </ng-container>
    </li>
  </ul>
</ng-template>