<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card 
      header="{{code}}" 
      detail="app.action.{{todo}}" 
      [isLoading]="formLoading" 
      [isSaving]="formSaving">

      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <br><br>

        <div *ngIf="visibilityConfig['processDate']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'qc.form.processDate' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker-x size="MD" formControlName="processDate" [time]=true [minDate]="currentDate">
            </app-date-picker-x>
          </div>
        </div>

        <div *ngIf="visibilityConfig['shift']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'qc.form.shift' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select type="COMBOBOX" formControlName="shift" placeholder="- select -" [optionList]="shiftOptionList"> </app-dropdown-select> 
          </div>
        </div>

        <div *ngIf="visibilityConfig['product']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'qc.form.product' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select type="COMBOBOX" formControlName="product" placeholder="- select -" [optionList]="productOptionList"> </app-dropdown-select> 
          </div>
        </div>

        <div *ngIf="visibilityConfig['productType']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'qc.form.productType' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select type="COMBOBOX" formControlName="productType" placeholder="- select -" [optionList]="productTypeOptionList"> </app-dropdown-select> 
          </div>
        </div>

        <div *ngIf="visibilityConfig['volume']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'qc.form.volume' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select type="COMBOBOX" formControlName="volume" placeholder="- select -" [optionList]="volumeOptionList"> </app-dropdown-select> 
          </div>
        </div>





        <div *ngIf="visibilityConfig['batch']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'qc.form.batch' | translate}}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="batch" type="alphanumeric" maxLength="64"></app-text-field>
          </div>
        </div>


        <div *ngIf="visibilityConfig['machineNumber']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'qc.form.machineNumber' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select type="COMBOBOX" formControlName="machineNumber" placeholder="- select -" [optionList]="machineNumberOptionList"> </app-dropdown-select> 
          </div>
        </div>


        <div *ngIf="visibilityConfig['carType']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'qc.form.carType' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            
            <app-dropdown-select type="COMBOBOX" formControlName="carType" placeholder="- select -" [optionList]="carTypeOptionList"> </app-dropdown-select> 
          </div>
        </div>

        <div *ngIf="visibilityConfig['carNumber']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'qc.form.carNumber' | translate}}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="carNumber" type="alphanumeric" maxLength="64"></app-text-field>
          </div>
        </div>

        <div *ngIf="visibilityConfig['expedition']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'qc.form.expedition' | translate}}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="expedition" type="alphanumeric" maxLength="64"></app-text-field>
          </div>
        </div>

        <div *ngIf="visibilityConfig['destination']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'qc.form.destination' | translate}}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="destination" type="alphanumeric" maxLength="64"></app-text-field>
          </div>
        </div>

        <div *ngIf="visibilityConfig['stuffingOperator']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'qc.form.stuffingOperator' | translate}}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="stuffingOperator" type="alphanumeric" maxLength="64"></app-text-field>
          </div>
        </div>



        <div *ngIf="visibilityConfig['isDt1']" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'qc.form.isDetector1' |translate }}</label>
          <div class="col-sm-9 text-left">
            <app-toggle-switch formControlName="isDt1"></app-toggle-switch>
          </div>
        </div>

        <div *ngIf="visibilityConfig['isDt2']" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'qc.form.isDetector2' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-toggle-switch formControlName="isDt2"></app-toggle-switch>
          </div>
        </div>

        <div *ngIf="visibilityConfig['isDt3']" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'qc.form.isDetector3' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-toggle-switch formControlName="isDt3"></app-toggle-switch>
          </div>
        </div>

        <div *ngIf="visibilityConfig['sampleCount']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'qc.form.sampleCount' | translate}}</label>
          <div class="input-group col-sm-4 text-left">
            <input type="range" formControlName="sampleCount" class="custom-range form-control" min="0" max="20"
              step="1">
            <div class="input-group-append " style="margin-top: -6px !important; margin-left: 4px !important;">
              <span class="input-group-text "
                style=" min-width: 43px !important;"><b>{{formGroup.get('sampleCount').value}} </b> </span>
            </div>
          </div>
          <div class="col-sm-4">
            <div *ngIf="formGroup.get('sampleCount').invalid && (formGroup.get('sampleCount').dirty || formGroup.get('sampleCount').touched)">
              <small *ngIf="formGroup.get('sampleCount').errors.required" class="text-danger">This field is required</small>
            </div>
          </div>
        </div>


        <div *ngIf="visibilityConfig['sampleOkCount']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'qc.form.sampleOkCount' | translate}}</label>
          <div class="input-group col-sm-4 text-left">
            <input type="range" formControlName="sampleOkCount" class="custom-range form-control" min="0" max="20"
              step="1">
            <div class="input-group-append " style="margin-top: -6px !important; margin-left: 4px !important;">
              <span class="input-group-text "
                style=" min-width: 43px !important;"><b>{{formGroup.get('sampleOkCount').value}} </b> </span>
            </div>
          </div>
          <div class="col-sm-4">
            <div *ngIf="formGroup.get('sampleOkCount').invalid && (formGroup.get('sampleOkCount').dirty || formGroup.get('sampleOkCount').touched)">
              <small *ngIf="formGroup.get('sampleOkCount').errors.required" class="text-danger">This field is required</small>
            </div>
          </div>
        </div>

        <div *ngIf="visibilityConfig['sampleDefectCount']" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'qc.form.sampleDefectCount' | translate}}</label>
          <div class="input-group col-sm-4 text-left">
            <input type="range" formControlName="sampleDefectCount" class="custom-range form-control" min="0" max="20"
              step="1">
            <div class="input-group-append " style="margin-top: -6px !important; margin-left: 4px !important;">
              <span class="input-group-text "
                style=" min-width: 43px !important;"><b>{{formGroup.get('sampleDefectCount').value}} </b> </span>
            </div>
          </div>
          <div class="col-sm-4">
            <div *ngIf="formGroup.get('sampleDefectCount').invalid && (formGroup.get('sampleDefectCount').dirty || formGroup.get('sampleDefectCount').touched)">
              <small *ngIf="formGroup.get('sampleDefectCount').errors.required" class="text-danger">This field is required</small>
            </div>
          </div>
        </div>

        
        
        <div *ngIf="visibilityConfig['defectList']" formArrayName="defectList">
          <h1 style="margin-top: 3rem !important; margin-bottom: 2rem !important;" class="text-muted">Defect</h1>
          <section *ngFor="let category of formGroup.get('defectList')['controls']; let i = index"
            [formGroupName]="i">
            <h5 style=" margin-bottom: 2rem !important;"  class="text-muted"><i>{{ category.get('name').value }}</i></h5>
            <section formArrayName="child">
              <section *ngFor="let child of category.get('child').controls; let j = index" [formGroupName]="j">
                <div class="form-group row text-sm-right required">
                  <label class="col-sm-3 control-label">{{ child.get('name').value }}</label>
                  <div class="input-group col-sm-4 text-left">

                    <input type="range" formControlName="value" class="custom-range form-control" min="0" max="20"
                      step="1">
                    <div class="input-group-append " style="margin-top: -6px !important; margin-left: 4px !important;">
                      <span class="input-group-text "
                        style=" min-width: 43px !important;"><b>{{child.get('value').value}} </b> </span>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>



        <section *ngIf="visibilityConfig['carConditionList']" formArrayName="carConditionList">
          <h1 style="margin-top: 3rem !important; margin-bottom: 2rem !important;" class="text-muted">Car Condition</h1>
          <section *ngFor="let category of formGroup.get('carConditionList')['controls']; let i = index"
            [formGroupName]="i">
            <h5 style=" margin-bottom: 2rem !important;"  class="text-muted"><i>{{ category.get('name').value }}</i></h5>
            <section formArrayName="child">
              <section *ngFor="let child of category.get('child').controls; let j = index" [formGroupName]="j">
                <div class="form-group row text-sm-right required">
                  <label class="col-sm-3 control-label">{{ child.get('name').value }}</label>
                  <div class="col-sm-9 text-left">
                    <app-toggle-switch formControlName="value"></app-toggle-switch>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </section>



        <section *ngIf="visibilityConfig['supplierList']" formArrayName="supplierList">
          <h1 style="margin-top: 3rem !important; margin-bottom: 2rem !important;" class="text-muted">Supplier</h1>
          <section *ngFor="let part of formGroup.get('supplierList')['controls']; let i = index" [formGroupName]="i"
            class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ part.get('name').value }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box [optionList]="supplierListOptionList[i]" formControlName="value"></app-combo-box>
             
            </div>
          </section>
        </section> 





        <section  style="margin-top: 3rem !important; margin-bottom: 4rem !important;" *ngIf="visibilityConfig['tqCount1']  || visibilityConfig['tqCount2'] || visibilityConfig['tqCount3']">
          <h1 style="margin-top: 3rem !important; margin-bottom: 2rem !important;" class="text-muted">Torque</h1>

          <div *ngIf="visibilityConfig['tqCount1']" class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ 'qc.form.tqCount1' | translate}}</label>
            <div class="input-group col-sm-4 text-left">
              <input type="range" formControlName="tqCount1" class="custom-range form-control" min="0" max="20"
                step="1">
              <div class="input-group-append " style="margin-top: -6px !important; margin-left: 4px !important;">
                <span class="input-group-text "
                  style=" min-width: 43px !important;"><b>{{formGroup.get('tqCount1').value}} </b> </span>
              </div>
            </div>
            <div class="col-sm-4">
              <div *ngIf="formGroup.get('tqCount1').invalid && (formGroup.get('tqCount1').dirty || formGroup.get('tqCount1').touched)">
                <small *ngIf="formGroup.get('tqCount1').errors.required" class="text-danger">This field is required</small>
              </div>
            </div>
          </div>

          <div *ngIf="visibilityConfig['tqCount2']" class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ 'qc.form.tqCount2' | translate}}</label>
            <div class="input-group col-sm-4 text-left">
              <input type="range" formControlName="tqCount2" class="custom-range form-control" min="0" max="20"
                step="1">
              <div class="input-group-append " style="margin-top: -6px !important; margin-left: 4px !important;">
                <span class="input-group-text "
                  style=" min-width: 43px !important;"><b>{{formGroup.get('tqCount2').value}} </b> </span>
              </div>
            </div>
            <div class="col-sm-4">
              <div *ngIf="formGroup.get('tqCount2').invalid && (formGroup.get('tqCount2').dirty || formGroup.get('tqCount2').touched)">
                <small *ngIf="formGroup.get('tqCount2').errors.required" class="text-danger">This field is required</small>
              </div>
            </div>
          </div>



          <div *ngIf="visibilityConfig['tqCount3']" class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ 'qc.form.tqCount3' | translate}}</label>
            <div class="input-group col-sm-4 text-left">
              <input type="range" formControlName="tqCount3" class="custom-range form-control" min="0" max="20"
                step="1">
              <div class="input-group-append " style="margin-top: -6px !important; margin-left: 4px !important;">
                <span class="input-group-text "
                  style=" min-width: 43px !important;"><b>{{formGroup.get('tqCount3').value}} </b> </span>
              </div>
            </div>
            <div class="col-sm-4">
              <div *ngIf="formGroup.get('tqCount3').invalid && (formGroup.get('tqCount3').dirty || formGroup.get('tqCount3').touched)">
                <small *ngIf="formGroup.get('tqCount3').errors.required" class="text-danger">This field is required</small>
              </div>
            </div>
          </div>
        </section>
    







        <div *ngIf="visibilityConfig['notes']" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'qc.form.notes' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area maxLength="512" formControlName="notes">
            </app-text-area>
          </div>
        </div>


        <br><br>
        
        <div class="row justify-content-center  mb-4">
          <div class="col-12 col-sm-2 mb-2">
            <button type="submit" class="btn btn-outline-secondary btn-block" (click)="doCancel()">{{ 'app.button.back'
              | translate}}</button>
          </div>
          <div *ngIf="!isViewOnly" class="col-12 col-sm-2 mb-2">
            <button  type="submit" class="btn btn-outline-secondary btn-block" (click)="doSave(false)">{{ 'app.button.save' |
              translate}}</button>
          </div>


          <!-- https://codepen.io/M-Araujo/pen/oqJvGM -->

       
          <div *ngIf="!isViewOnly"  class="col-12 col-sm-2 mb-2">
            <button type="submit" class="btn  btn-primary btn-block" (click)="doSave(true)"> {{ 'app.button.submit' | translate}}</button>
          </div>
        </div>

      </form>

    </app-card>
  </div>
</div>