/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppConstant } from '../constant/app-constant';
import { BuildInfoDTO } from '../dto/build-info.dto';
import { BlockMsgModel } from '../model/block-msg-model';
import { ConfigModel } from '../model/config-model';
import { NavigationHistoryModel } from '../model/navigation-history-model';
import { ResponseStatusModel } from '../model/response-status-model';
import { UserSessionModel } from '../model/user-session-model';
import { AlertService } from '../services/alert.service';
import { ConverterService } from '../services/converter.service';
import { CookieService } from '../services/cookie.service';
import { DownloadService } from '../services/download.service';
import { HttpClientService } from '../services/http-client.service';
import { ModalService } from '../services/modal.service';
import { Utils } from './utils';

@Injectable()
export class Global {
  public userSession: UserSessionModel;
  public routerParams: Map<string, any>;
  public blockMsg: BlockMsgModel = new BlockMsgModel();
  public currentLang: string;
  public config: ConfigModel = new ConfigModel();
  public buildInfoDTO: BuildInfoDTO = new BuildInfoDTO();
  public showSideBar = true;
  public activeModuleCode: string;
  public isModuleChange = false;
  public mainLogoFileName: string;
  public smallLogoFileName: string;
  public deltaTime: number; // in milliseconds
  public lastActiveRouterList: Array<string> = new Array();
  public widgetLoadingStatus: Map<string, boolean>;
  public appConstant: AppConstant = new AppConstant();
  public utils: Utils = new Utils();
  public navigationHistory: NavigationHistoryModel =
    new NavigationHistoryModel();

  public primaryColor: string;
  public colorOnPrimaryColor: string;
  public primaryDarkColor: string;
  public colorOnPrimaryDarkColor: string;
  public tableHeaderColor: string;
  public colorOnTableHeaderColor: string;
  public tableBodyColor: string;
  public colorOnTableBodyColor: string;
  public textColor: string;

  constructor(
    public translateService: TranslateService,
    public modalService: ModalService,
    public alertService: AlertService,
    public converterService: ConverterService,
    public httpClientService: HttpClientService,
    public cookieService: CookieService,
    public downloadService: DownloadService
  ) {
    this.routerParams = new Map<string, any>();
    this.widgetLoadingStatus = new Map<string, boolean>();
    this.blockMsg.active = false;
    this.currentLang = null;
    console.log('[ global service loaded!! ]');
  }

  public blockUI(type: string, msg?: string): void {
    this.blockMsg.type = type;
    this.blockMsg.msg = msg;
    this.blockMsg.active = true;
  }

  public unblockUI(): void {
    this.blockMsg.active = false;
  }

  public copyFormAttributeToModel(obj: any, formGroup: FormGroup): any {
    const keys = Object.keys(formGroup.value);
    for (const key of keys) {
      obj[key] = formGroup.value[key];
    }
    return obj;
  }

  public userImageProfile(moduleCode: string, user: any): string {
    if (user && user.imgFile != null) {
      return user.imgFile.uploadedFileName == null
        ? this.appConstant.core.URL_DEFAULT_PROFILE_IMAGE
        : this.config.BACKEND_ADDRESS +
            '/' +
            moduleCode +
            '/file/view/' +
            user.imgFile.uploadedFileName +
            '/' +
            user.imgFile.fileType.code;
    } else {
      return this.appConstant.core.URL_DEFAULT_PROFILE_IMAGE;
    }
  }

  public changeLang(lang: string, from: string): void {
    this.currentLang = lang;
    console.log('[ change lang to ] ' + this.currentLang);
    localStorage.setItem('lang', this.currentLang);
    this.cookieService.set('lang', this.currentLang);
    this.translateService.use(this.currentLang);
    this.blockMsg.type = 'loading';
    this.blockUI('loading');
    this.httpClientService
      .get<Response>('/translator/change-lang/' + this.currentLang)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          console.log('Change Language OK');
          this.translateService.getTranslation(this.currentLang);
          this.unblockUI();
        }
      });
    this.routerParams.set('reload', from);
  }

  public getCurrentLang(): string {
    const cookieLang = localStorage.getItem('lang');
    if (cookieLang === null || cookieLang === '') {
      if (this.config.appInfoResponse !== undefined) {
        this.currentLang =
          this.config.appInfoResponse.defaultLang.code.toLowerCase();
      } else {
        this.currentLang = 'id';
      }
      localStorage.setItem('lang', this.currentLang);
    } else {
      this.currentLang = localStorage.getItem('lang');
    }
    this.cookieService.set('lang', this.currentLang);
    return this.currentLang;
  }

  public download(
    event: MouseEvent,
    stringUrl: string,
    fileName: string
  ): void {
    this.downloadFile(stringUrl, fileName, event);
  }

  public downloadFile(
    stringUrl: string,
    fileName: string,
    event?: MouseEvent
  ): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.downloadService.download(stringUrl, fileName);
  }

  public getIsMenuWrite(): boolean {
    if (this.userSession) {
      if (this.activeModuleCode != null) {
        const moduleCode = this.activeModuleCode.toUpperCase();
        const menu = this.userSession.menuMap[moduleCode];
        if (menu) {
          return menu.write;
        }
      } else {
        return true;
      }
    }
  }
}
